.container-header {
  /* background: pink; */
  padding: 1em;
  text-align: center;
}

.icons {
  font-size: 1.75em;
  margin: 0 0.75em;
  color: var(--secondary);
  cursor: pointer;
}

.icons:focus,
.icons:hover {
  color: var(--tertiary);
}

.header-icons {
  list-style-type: none;
  padding: 0;

  display: flex;
  justify-content: center;
}
