/* ICONS & PHOTOS */

.about-me-photo {
  object-fit: cover;
  object-position: center;
  max-width: 100%;
  height: 50vh;
  order: 2;
  margin: auto 0;
  margin-bottom: 1.5em;
  border: 5px var(--primary) solid;
  border-radius: 7px;
}

/* LAYOUT */

.about-me-section {
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
}

.about-me-main {
  order: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about-me-body {
  text-align: center;
  margin-bottom: 2em;
}

@media (min-width: 700px) {
  .about-me-section {
    flex-direction: row;
  }

  .about-me-photo {
    max-width: 50%;
    height: 80vh;
    margin: auto 0;
  }

  .about-me-main {
    order: 1;
    text-align: right;
    margin-right: 1.5em;
  }

  .about-me-body {
    text-align: right;
  }
}
