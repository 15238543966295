.icons-social-media a {
  color: var(--secondary);
}

.icons-social-media a:focus,
.icons-social-media a:hover {
  color: var(--tertiary);
}

.middle-icon {
  margin: 0 1em;
}
