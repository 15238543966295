* {
  box-sizing: border-box;
}

:root {
  --background: #ffffff;
  --primary: #bed3c3;
  --secondary: #8ba6a9;
  --tertiary: #476a6f;
  --text-color: #272727;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0 1em;
  background: var(--background);
  color: var(--text-color);
}

span {
  margin: 0;
  padding: 0;
}

/* TYPOGRAPHY */
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.17rem;
}

p {
  font-size: 1rem;
}

@media (min-width: 700px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.25rem;
  }

  h3 {
    font-size: 1.755rem;
  }

  p {
    font-size: 1rem;
  }
}
/* ICONS & PHOTOS */

/* LAYOUT */

.page-container {
  text-align: center;
  min-height: 95vh;
  margin: auto;
  padding: 0;

  /* background: blue; */
}

.page-title {
  text-align: left;
  font-size: 2.5rem;
  color: var(--tertiary);
  margin: 0;
  /* background: red; */
}

.page-section {
  margin: 0 auto;
  width: 80%;
  min-height: 65vh;

  /* background: green; */
}

@media (min-width: 700px) {
  .page-title {
    font-size: 3.5rem;
  }

  .page-section {
    width: 70%;
  }
}

/* MISC */

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border-radius: 7px;
}
