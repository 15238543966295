/* FORM */

input,
textarea {
  width: 100%;
  padding: 0.5em;
  margin: 0.5em 0;
  border: none;
  border: 3px var(--primary) solid;
  border-radius: 7px;
  color: var(--tertiary);
  font-size: 1rem;
}

button {
  cursor: pointer;
  border-radius: 7px;
  color: var(--background);
  border: none;
  border: 3px var(--secondary) solid;
  background-color: var(--secondary);
}

button:focus,
button:hover {
  border: 3px var(--tertiary) solid;
  background-color: var(--tertiary);
  outline: none;
}

input:focus,
input:hover,
textarea:focus,
textarea:hover {
  border: 3px var(--secondary) solid;
  outline: none;
}

textarea {
  resize: vertical;
}

.button-submit {
  padding: 0.5em;
  margin-top: 1em;
  width: 50%;
}

.contact-form {
  width: 100%;
  margin: auto;

  /* background-color: purple; */
}

.input-contact-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.input-contact-info-children {
  width: 49%;
}

@media (min-width: 700px) {
  .contact-form {
    width: 70%;
  }
}
