/* TYPOGRAPHY */

.button-active {
  background: var(--secondary);
  border-color: var(--secondary);
}

/* ICONS & PHOTOS */

.button-tech {
  margin: 0.7em;
  font-size: 0.8rem;
}

/* LAYOUT */

.button-tech-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.projects-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1em;
}

.projects-section {
  padding: 5vh 0;
}

/* if screen is over 800px */
@media (min-width: 800px) {
  .projects-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

/* if screen is over 1250px */
@media (min-width: 1250px) {
  .button-tech-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
  }

  .button-tech {
    font-size: 1rem;
    margin: 0.75em;
  }
}
