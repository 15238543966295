/* TYPOGRAPHY */

.header-landing {
  font-size: 3rem;

  color: var(--tertiary);
}

.intro-landing,
.title-landing {
  font-size: 1rem;
  margin: 0;
  color: var(--tertiary);
}

.icon-scroll {
  margin-top: 0vh;
  color: var(--secondary);
  animation-duration: 5s;
}

.Typist .Cursor {
  display: inline-block;
}
.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1s linear infinite;
}

a:hover,
a:focus {
  color: var(--tertiary);
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* LAYOUT */

.landing-page-section {
  /* background: purple; */
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.body-landing {
  /* background: orange; */
  height: 65vh;
  padding-top: 14vh;
}

@media (min-width: 510px) {
  .header-landing {
    font-size: 4.5rem;
  }
}

@media (min-width: 700px) {
  .header-landing {
    font-size: 5.5rem;
  }

  .body-landing {
    /* background: orange; */
    height: 65vh;
    padding-top: 10vh;
  }

  .intro-landing,
  .title-landing {
    font-size: 1.5rem;
  }
}

@media (min-width: 1000px) {
  .header-landing {
    font-size: 6rem;
  }
}
