/* TYPOGRAPHY */

.signature {
  font-size: 0.75rem;
  text-align: center;
  color: var(--tertiary);
  margin-top: 0.75em;
}

/* LAYOUT */

.contact-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15vh;
}
