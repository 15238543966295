/* TYPOGRAPHY */

.displayName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--background);
  font-weight: 700;
  font-size: 2rem;
}

/* ICONS & PHOTOS */

.project-image {
  width: 100%;
  height: initial;
  object-fit: cover;
  border: 5px var(--primary) solid;
  border-radius: 7px;
  margin: 0;
}

.project-container {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 1.25em 0;
}

.project-image-hover {
  opacity: 10%;
}

.project-img-container:focus,
.project-img-container:hover {
  outline: none;
}

.card {
  background: linear-gradient(180deg, var(--secondary), var(--tertiary));
  border-radius: 7px;
  border: 0px var(--primary) solid;

  background-size: cover;
  background-position: center center;
  /* box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s; */
  will-change: transform;
}
/* MEDIA QUERIES */

/* if screen is over 800px */
@media (min-width: 800px) {
  .project-container {
    width: 48%;
  }

  .project-image {
    height: 17vw;
  }
}
